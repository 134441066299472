import type { Attachment } from "@ai-sdk/ui-utils";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Paper,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  Link,
  useFetcher,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "@remix-run/react";
import { AssignmentWithQuestions } from "modules/assignments/assignment.types";
import { GroupDropdownItem } from "modules/groups/group.types";
import { useEffect, useRef, useState } from "react";
import {
  MdAdd,
  MdArrowBack,
  MdEditDocument,
  MdHistoryEdu,
  MdPersonAdd,
  MdSave,
} from "react-icons/md";
import { TbRobot } from "react-icons/tb";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { OutletContext } from "~/root";
import DraggableQuestionsView from "~/routes/teacher.assignments.$assignmentId_.edit/DraggableQuestionsView";
import ExportDropdown from "~/routes/teacher.assignments.$assignmentId_.edit/ExportDropdown";
import HomeAssignmentSignIn from "../Button/HomeAssignmentSignIn";
import { AssignmentContextProvider } from "./AssignmentContext";
import PanelContent, { PanelContentType } from "./PanelContent";
import PanelHeader from "./PanelHeader";
import PublishSettings from "./PublishSettings";

type AssignmentEditorProps = {
  assignment: AssignmentWithQuestions | null;
  onClose?: () => void;
  groups?: GroupDropdownItem[];
  initialInput?: string;
  initialAttachments?: Attachment[];
};

export default function AssignmentEditor({
  assignment,
  onClose,
  groups = [],
  initialInput,
  initialAttachments = [],
}: AssignmentEditorProps) {
  const { user, teacherProfile } = useOutletContext<OutletContext>();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const rightPanelRef = useRef<ImperativePanelHandle>(null);
  const leftPanelRef = useRef<ImperativePanelHandle>(null);
  const questionsContainerRef = useRef<HTMLDivElement>(null);
  const resetChatRef = useRef<(() => void) | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<string>("view-assignment");
  const [searchParams] = useSearchParams();

  const [activePanelContent, setActivePanelContent] =
    useState<PanelContentType>("ai-assistant");
  const [chatResetting, setChatResetting] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Handle initial panel state for mobile
  useEffect(() => {
    if (isMobile) {
      // Initialize to editor view by default on mobile
      setActiveTab("editor");
      leftPanelRef.current?.expand(); // Show editor panel
      rightPanelRef.current?.collapse(); // Hide AI panel
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, leftPanelRef.current, rightPanelRef.current]);

  const createEmptyQuestionFetcher = useFetcher();
  const handleCreateEmptyQuestion = () => {
    if (!assignment || createEmptyQuestionFetcher.state !== "idle") return;
    if (isMobile) {
      setActiveTab("view-assignment");
      rightPanelRef.current?.collapse();
    }
    createEmptyQuestionFetcher.submit(
      JSON.stringify({ assignmentId: assignment.id.toString() }),
      {
        method: "POST",
        action: "/api/assignments/questions",
        encType: "application/json",
      },
    );
  };

  useEffect(() => {
    if (!isRightPanelOpen && rightPanelRef.current) {
      // Check if the panel is not already collapsed to avoid unnecessary calls
      if (!rightPanelRef.current.isCollapsed()) {
        rightPanelRef.current.collapse();
      }
    }
    // Run only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array

  const questionCount = assignment?.assignmentQuestions?.length || 0;
  const prevCount = useRef(questionCount);

  useEffect(() => {
    if (questionCount > prevCount.current) {
      questionsContainerRef.current?.scrollTo({
        behavior: "smooth",
        top: questionsContainerRef.current.scrollHeight,
      });
    }
    prevCount.current = questionCount;
  }, [questionCount]);

  const handleRightClose = () => {
    rightPanelRef.current?.collapse();
    setIsRightPanelOpen(false);
  };

  const handleRightOpen = (mode?: PanelContentType) => {
    if (mode) {
      setActivePanelContent(mode);
    }
    if (isMobile) {
      leftPanelRef.current?.collapse();
      rightPanelRef.current?.expand();
      setActiveTab(mode ? mode : "ai");
    } else {
      rightPanelRef.current?.expand();
      setIsRightPanelOpen(true);
    }
  };

  // Handler to open the right panel and set content to question search
  const handleSearchContentOpen = () => {
    handleRightOpen(); // Reuse logic to open/expand the panel
    setActivePanelContent("question-search"); // Set the panel content type
  };

  const handleTabChange = (value: string | null) => {
    if (!value) return;

    setActiveTab(value);

    if (isMobile) {
      if (value === "view-assignment") {
        leftPanelRef.current?.expand();
        rightPanelRef.current?.collapse();
      } else if (value === "create-questions") {
        rightPanelRef.current?.expand();
        leftPanelRef.current?.collapse();
      }
    }
  };

  // Handle reset chat button click
  const handleResetChat = async () => {
    try {
      setChatResetting(true);
      // Create a new chat using fetch
      const response = await fetch("/api/chat/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to create new chat");
      }

      const newChat = await response.json();

      // Update the URL with the new chatId
      const url = new URL(window.location.href);
      url.searchParams.set("chatId", newChat.id.toString());

      // Navigate to the same page with the new chatId
      navigate(`${location.pathname}?${url.searchParams.toString()}`, {
        replace: false,
      });
    } catch (error) {
      console.error("Error creating new chat:", error);
    } finally {
      setChatResetting(false);
    }
  };

  const truncateTitle = (text: string, maxLength = isMobile ? 12 : 40) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  // Stringify the assignment object for the AI assistant
  const assignmentContext = {
    assignment: JSON.stringify(assignment),
  };

  // Safe onClose handler that won't be undefined
  const handlePanelHeaderClose = () => {
    if (!isMobile) {
      handleRightClose();
    }
  };

  if (!assignment) {
    return null;
  }
  const primaryActionButton = () => {
    if (user && teacherProfile) {
      return <PublishSettings assignment={assignment} groups={groups} />;
    }
    if (user && !teacherProfile) {
      // Get current assignmentId and chatId from URL or context
      const assignmentId = assignment.id.toString();
      const chatId = searchParams.get("chatId") || "";

      return (
        <Button
          component={Link}
          to={`/teacher/pre-pilot?assignmentId=${assignmentId}&chatId=${chatId}`}
          leftSection={<MdSave size={20} />}
        >
          Save Assignment
        </Button>
      );
    }
    return (
      <Button
        component={HomeAssignmentSignIn}
        className="variant-filled color-primary size-sm"
        leftSection={<MdPersonAdd size={20} />}
      >
        Sign Up
      </Button>
    );
  };
  return (
    <AssignmentContextProvider assignmentId={assignment.id.toString()}>
      <Box className="w-full overflow-hidden h-screen flex flex-col">
        {/* Main header - for the entire editor */}
        <Flex className="border-b border-gray-200 p-3 h-14 items-center justify-between bg-secondary-600 text-white">
          <Flex gap="md" align="center">
            <ActionIcon
              variant="subtle"
              color="white"
              onClick={onClose}
              title="Go back"
            >
              <MdArrowBack size={20} />
            </ActionIcon>

            <Text className="font-medium text-lg">
              {truncateTitle(assignment.title || "Untitled Assignment")}
            </Text>
          </Flex>
          <Flex gap="sm" align="center">
            <ExportDropdown assignment={assignment} />
            {primaryActionButton()}
          </Flex>
        </Flex>

        {/* Mobile Tabs */}
        {isMobile && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className="bg-slate-50"
            radius="none"
          >
            <Tabs.List>
              <Tabs.Tab
                value="view-assignment"
                className="flex-1 text-center"
                leftSection={<MdEditDocument size={18} />}
              >
                View Assignment
              </Tabs.Tab>
              <Tabs.Tab
                value="create-questions"
                className="flex-1 text-center"
                leftSection={<TbRobot size={18} />}
              >
                Create Questions
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        )}

        {/* Panels container - takes remaining height */}
        <PanelGroup direction="horizontal" className="flex-1">
          {/* Left Panel - Assignment Editor Area (main area) */}
          <Panel
            ref={leftPanelRef}
            defaultSize={!isRightPanelOpen ? 100 : 60}
            minSize={0}
            collapsible={isMobile}
          >
            <div
              ref={questionsContainerRef}
              className="h-full p-4 relative overflow-auto bg-neutral-50"
            >
              {/* Button to open AI panel when it's closed */}
              {!isRightPanelOpen && !isMobile && (
                <div className="fixed top-16 right-3 z-10 flex flex-col gap-2">
                  <Tooltip label="AI Create" withArrow>
                    <Button
                      variant="filled"
                      color="secondary"
                      onClick={() => handleRightOpen("ai-assistant")}
                      size="md"
                      className="shadow-md hover:shadow-lg transition-shadow rounded-full p-2"
                    >
                      <TbRobot size={24} />
                    </Button>
                  </Tooltip>
                  <Tooltip label="Pre-Made Content" withArrow>
                    <Button
                      variant="filled"
                      color="secondary"
                      onClick={() => handleRightOpen("question-search")}
                      size="md"
                      className="shadow-md hover:shadow-lg transition-shadow rounded-full p-2"
                    >
                      <MdHistoryEdu size={24} />
                    </Button>
                  </Tooltip>
                  <Tooltip label="Manual" withArrow>
                    <Button
                      variant="filled"
                      color="secondary"
                      onClick={handleCreateEmptyQuestion}
                      size="md"
                      className="shadow-md hover:shadow-lg transition-shadow rounded-full p-2"
                    >
                      <MdAdd size={24} />
                    </Button>
                  </Tooltip>
                </div>
              )}

              <div className="max-w-5xl mx-auto pt-12 sm:px-4 px-0">
                <DraggableQuestionsView
                  questions={assignment?.assignmentQuestions || []}
                  assignmentId={assignment?.id.toString() || ""}
                  canAddQuestions={true}
                  onAIAssistantClick={() => handleRightOpen("ai-assistant")}
                  onSearchContentClick={handleSearchContentOpen}
                  handleCreateEmptyQuestion={handleCreateEmptyQuestion}
                />
              </div>
            </div>
          </Panel>

          {/* Right Panel - AI Assistant or Question Search */}
          <PanelResizeHandle className="w-0.5 bg-gray-200 hover:bg-gray-300 transition-colors" />
          <Panel
            ref={rightPanelRef}
            defaultSize={40}
            minSize={0}
            maxSize={isMobile ? 100 : 60}
            collapsible
            onCollapse={() => setIsRightPanelOpen(false)}
            onExpand={() => setIsRightPanelOpen(true)}
          >
            <Paper className="h-full flex flex-col" radius={0} p={0}>
              {/* Toolbar with content selector, reset and close buttons */}
              <PanelHeader
                isMobile={isMobile}
                activePanelContent={activePanelContent}
                setActivePanelContent={setActivePanelContent}
                onResetChat={handleResetChat}
                onClose={handlePanelHeaderClose}
                handleCreateEmptyQuestion={handleCreateEmptyQuestion}
                chatResetting={chatResetting}
              />

              {/* Panel content */}
              <Box className="flex-1 overflow-hidden">
                <PanelContent
                  type={activePanelContent}
                  assignmentContext={assignmentContext}
                  resetChatRef={resetChatRef}
                  initialInput={initialInput}
                  initialAttachments={initialAttachments}
                />
              </Box>
            </Paper>
          </Panel>
        </PanelGroup>
      </Box>
    </AssignmentContextProvider>
  );
}

import { Modal, Tabs } from "@mantine/core";
import { AssignmentQuestion } from "modules/questions/question.types";
import { useEffect, useState } from "react";
import { QuestionContentEditor } from "~/components/QuestionContentEditor/QuestionContentEditor";
import QuestionEditorAIAssistant from "./QuestionEditorAIAssistant";
import { useMediaQuery } from "@mantine/hooks";

interface QuestionEditorAssistantProps {
  opened: boolean;
  onClose: () => void;
  question: AssignmentQuestion["question"];
  onContentChange: (
    value: string,
    field: "questionText" | "explanation",
  ) => void;
}

export default function QuestionEditorAssistant({
  opened,
  onClose,
  question,
  onContentChange,
}: QuestionEditorAssistantProps) {
  const [waitingForUpdatedContent, setWaitingForUpdatedContent] =
    useState(false);
  const [numAIUpdates, setNumAIUpdates] = useState(0);

  useEffect(() => {
    // if both waiting for ai update and question content is updated, then notify that QuestionContentEditor needs to re-render:
    if (waitingForUpdatedContent) {
      setNumAIUpdates(numAIUpdates + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <Modal.Root opened={opened} onClose={onClose} fullScreen>
      <Modal.Overlay />
      <Modal.Content className="h-screen w-screen flex flex-col">
        <Modal.Header className="bg-primary-200">
          <Modal.Title>Question Editor</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body className="flex-1 min-h-0 p-0">
          {isDesktop ? (
            <div className="flex h-full">
              <div className="flex-1 overflow-auto p-8">
                <QuestionContentEditor
                  key={numAIUpdates}
                  question={question}
                  onContentChange={onContentChange}
                />
              </div>
              <div className="flex-1 overflow-auto p-8 border-r border-gray-300">
                <QuestionEditorAIAssistant
                  question={question}
                  setWaitingForUpdatedContent={setWaitingForUpdatedContent}
                />
              </div>
            </div>
          ) : (
            <Tabs defaultValue="ai-assistant" className="h-full flex flex-col">
              <Tabs.List>
                <Tabs.Tab value="question-preview">Question Preview</Tabs.Tab>
                <Tabs.Tab value="ai-assistant">AI Assistant</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel
                value="ai-assistant"
                className="flex-1 overflow-auto p-2"
              >
                <QuestionEditorAIAssistant
                  question={question}
                  setWaitingForUpdatedContent={setWaitingForUpdatedContent}
                />
              </Tabs.Panel>
              <Tabs.Panel
                value="question-preview"
                className="flex-1 overflow-auto p-2"
              >
                <QuestionContentEditor
                  key={numAIUpdates}
                  question={question}
                  onContentChange={onContentChange}
                />
              </Tabs.Panel>
            </Tabs>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

import { ActionIcon, Box, Button, Flex, Menu } from "@mantine/core";
import { MdAdd, MdArrowDropDown, MdClose } from "react-icons/md";
import { PanelContentType } from "./PanelContent";

type PanelHeaderProps = {
  activePanelContent: PanelContentType;
  setActivePanelContent: (value: PanelContentType) => void;
  onResetChat: () => void;
  chatResetting?: boolean;
  onClose: () => void;
  isMobile?: boolean;
  handleCreateEmptyQuestion: () => void; // Added prop
};

export default function PanelHeader({
  activePanelContent,
  setActivePanelContent,
  onResetChat,
  chatResetting,
  onClose,
  isMobile,
  handleCreateEmptyQuestion, // Ensure prop is destructured
}: PanelHeaderProps) {
  return (
    <Box className="border-b border-gray-200 p-2 h-10 flex items-center justify-between bg-amber-50">
      <Box className="pl-2">
        <Menu withArrow>
          <Menu.Target>
            <Button
              variant="outline"
              size="xs"
              rightSection={<MdArrowDropDown size={16} />}
            >
              {activePanelContent === "ai-assistant" && "AI Create"}
              {activePanelContent === "question-search" &&
                "Foundational Questions"}
              {activePanelContent === "assignment-search" &&
                "Exam-Like Questions"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => setActivePanelContent("ai-assistant")}>
              AI Create
            </Menu.Item>
            <Menu.Label>Pre-Made Content</Menu.Label>
            <Menu.Item
              onClick={() => setActivePanelContent("question-search")}
              style={{ paddingLeft: "20px" }}
            >
              Foundational Questions
            </Menu.Item>
            <Menu.Item
              onClick={() => setActivePanelContent("assignment-search")}
              style={{ paddingLeft: "20px" }}
            >
              Exam-Like Questions
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                handleCreateEmptyQuestion();
                if (isMobile) {
                  onClose();
                }
              }}
            >
              Manual Create
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>
      <Flex gap="xs">
        {activePanelContent === "ai-assistant" && (
          <Button
            size="xs"
            variant="light"
            color="secondary"
            onClick={onResetChat}
            title="Start a new chat"
            leftSection={<MdAdd size={16} />}
            loading={chatResetting}
          >
            New Chat
          </Button>
        )}
        {!isMobile && (
          <ActionIcon variant="subtle" color="gray" onClick={onClose}>
            <MdClose size={16} />
          </ActionIcon>
        )}
      </Flex>
    </Box>
  );
}

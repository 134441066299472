import { ActionIcon, Button, Flex, Modal, Tooltip } from "@mantine/core";
import { useFetcher, useParams, useSearchParams } from "@remix-run/react";
import {
  AssignmentFormData,
  AssignmentWithQuestions,
  completeAssignmentSchema,
} from "modules/assignments/assignment.types";
import { GroupDropdownItem } from "modules/groups/group.types";
import { useState } from "react";
import { MdSettings, MdWarning } from "react-icons/md";
import { TbRocket } from "react-icons/tb";
import AssignmentForm from "~/components/Assignment/AssignmentForm";
import { action } from "~/routes/api.assignments.$assignmentId.publish";

interface PublishSettingsProps {
  assignment: AssignmentWithQuestions;
  groups?: GroupDropdownItem[];
}

export default function PublishSettings({
  assignment,
  groups = [],
}: PublishSettingsProps) {
  const [publishModalOpened, setPublishModalOpened] = useState(false);
  const publishFetcher = useFetcher<typeof action>();
  const [searchParams] = useSearchParams();
  const { assignmentId } = useParams();
  const publishAssignmentId = assignmentId || searchParams.get("assignmentId");

  const handleModalPublish = () => {
    publishFetcher.submit(null, {
      method: "post",
      action: `/api/assignments/${publishAssignmentId}/publish`,
      encType: "application/json",
    });
    setPublishModalOpened(false);
  };

  // Publish validation logic
  const isPublished = assignment.state === "active";
  const parseData = completeAssignmentSchema.safeParse(assignment);
  const isReadyToPublish = parseData.success;

  // Get all error messages from validation
  const errorMessages = parseData.success
    ? []
    : parseData.error.errors.map((err) => err.message);

  // Button title simplified - no longer includes error text
  const buttonTitle = isPublished
    ? "This assignment has already been published"
    : "Publish assignment";

  const publishButtonText = isPublished ? "Published" : "Publish";
  const publishButtonDisabled = isPublished || !isReadyToPublish;

  return (
    <>
      {/* Show ActionIcon only on mobile */}
      <div className="block sm:hidden">
        <ActionIcon
          color="primary"
          variant="filled"
          onClick={() => setPublishModalOpened(true)}
          size="lg"
        >
          <MdSettings size={18} />
        </ActionIcon>
      </div>

      {/* Show normal button with text on larger screens */}
      <div className="hidden sm:block">
        <Button
          leftSection={<MdSettings size={18} />}
          variant="filled"
          color="primary"
          onClick={() => setPublishModalOpened(true)}
        >
          Publish Settings
        </Button>
      </div>

      {/* Publish Modal */}
      <Modal
        opened={publishModalOpened}
        onClose={() => setPublishModalOpened(false)}
        title="Publish Assignment"
        size="lg"
      >
        <AssignmentForm
          initialData={assignment as AssignmentFormData}
          groups={groups}
          action={`/teacher/assignments/${assignment.id}/edit`}
        />
        <Flex justify="flex-end" mt="xl">
          <Button
            onClick={() => setPublishModalOpened(false)}
            variant="outline"
            mr="md"
          >
            Cancel
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              color={publishButtonDisabled ? "gray" : "primary"}
              leftSection={<TbRocket size={18} />}
              onClick={handleModalPublish}
              disabled={publishButtonDisabled}
              className={publishButtonDisabled ? "cursor-not-allowed" : ""}
              variant={publishButtonDisabled ? "light" : "filled"}
              title={buttonTitle}
            >
              {publishButtonText}
            </Button>

            {/* Error icon with tooltip - always reserves space but only visible when needed */}
            <div className="flex justify-center ml-1">
              {!isReadyToPublish && errorMessages.length > 0 && (
                <Tooltip
                  multiline
                  withArrow
                  position="top-end"
                  label={
                    <div>
                      <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                        Assignment cannot be published:
                      </div>
                      <ul style={{ margin: 0, paddingLeft: "16px" }}>
                        {errorMessages.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <div className="text-yellow-600">
                    <MdWarning size={16} />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </Flex>
      </Modal>
    </>
  );
}

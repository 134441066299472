import {
  Center,
  Flex,
  Loader,
  Paper,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import { MdHistoryEdu } from "react-icons/md";
import { TbPlus, TbRobot } from "react-icons/tb";
import { action } from "~/routes/api.assignments.questions"; // Assuming this is the correct action import
import { PanelContentType } from "./PanelContent";

interface AssignmentBuilderEmptyStateProps {
  assignmentId: string;
  onAIAssistantClick: (mode: PanelContentType) => void;
  onSearchContentClick: (mode: PanelContentType) => void;
  handleCreateEmptyQuestion: () => void; // Added prop
}

export default function AssignmentBuilderEmptyState({
  assignmentId,
  onAIAssistantClick,
  onSearchContentClick,
}: AssignmentBuilderEmptyStateProps) {
  const emptyQuestionFetcher = useFetcher<typeof action>();
  const isLoading = emptyQuestionFetcher.state !== "idle";

  const handleCreateEmptyQuestion = () => {
    // Prevent multiple submissions while loading
    if (isLoading) return;
    emptyQuestionFetcher.submit(JSON.stringify({ assignmentId }), {
      method: "POST",
      action: `/api/assignments/questions`,
      encType: "application/json",
    });
  };

  return (
    <Flex direction="column" align="center" gap="xl" className="py-10">
      <div className="text-center">
        <Title order={2} className="font-semibold mb-2">
          Assignment Builder
        </Title>
        <Text size="sm" c="dimmed">
          Choose an option to add questions to your assignment
        </Text>
      </div>

      <SimpleGrid
        cols={{ base: 1, sm: 3 }}
        spacing="lg"
        className="w-full max-w-4xl"
      >
        {/* AI Create Card */}
        <Paper
          withBorder
          shadow="sm"
          p="lg"
          radius="md"
          className="cursor-pointer hover:shadow-md transition-shadow"
          onClick={() => onAIAssistantClick("ai-assistant")}
        >
          <Flex direction="column" align="center" gap="sm">
            <TbRobot size={32} className="text-blue-600" />
            <Text fw={600} ta="center">
              AI create
            </Text>
            <Text size="xs" c="dimmed" ta="center">
              Use AI to generate a new question
            </Text>
          </Flex>
        </Paper>

        {/* Search Content Card */}
        <Paper
          withBorder
          shadow="sm"
          p="lg"
          radius="md"
          className="cursor-pointer hover:shadow-md transition-shadow"
          onClick={() => onSearchContentClick("question-search")}
        >
          <Flex direction="column" align="center" gap="sm">
            <MdHistoryEdu size={32} className="text-gray-600" />
            <Text fw={600} ta="center">
              Search pre-made content
            </Text>
            <Text size="xs" c="dimmed" ta="center">
              Find questions from a content library
            </Text>
          </Flex>
        </Paper>

        {/* Manual Add Card */}
        <Paper
          withBorder
          shadow="sm"
          p="lg"
          radius="md"
          className="cursor-pointer hover:shadow-md transition-shadow"
          onClick={handleCreateEmptyQuestion} // Use passed prop
        >
          <Flex direction="column" align="center" gap="sm" className="h-full">
            {isLoading ? (
              <Center className="h-full">
                <Loader size="sm" />
              </Center>
            ) : (
              <>
                <TbPlus size={32} className="text-green-600" />
                <Text fw={600} ta="center">
                  Manually add question
                </Text>
                <Text size="xs" c="dimmed" ta="center">
                  Create a question from scratch
                </Text>
              </>
            )}
          </Flex>
        </Paper>
      </SimpleGrid>
    </Flex>
  );
}

import { Box, Button } from "@mantine/core";
import type { KeyboardEvent as ReactKeyboardEvent } from "react";
import { useRef } from "react";
import type { EditorHandle } from "~/components/Editor/Editor";
import { Editor } from "~/components/Editor/Editor";

interface MessageInputProps {
  onSubmit: () => void;
  onChange: (value: string) => void;
  isStreaming: boolean;
  editorKey: number;
}

export function MessageInput({
  onSubmit,
  onChange,
  isStreaming,
  editorKey,
}: MessageInputProps) {
  const editorRef = useRef<EditorHandle>(null);

  const handleKeyDown = (e: ReactKeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <Box className="border-t border-gray-200 p-4">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="flex gap-2"
      >
        <Box className="flex-1" onKeyDown={handleKeyDown}>
          <Editor key={editorKey} editorRef={editorRef} onChange={onChange} />
        </Box>
        <Button type="submit" loading={isStreaming} disabled={isStreaming}>
          Send
        </Button>
      </form>
    </Box>
  );
}

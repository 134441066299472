import { Button } from "@mantine/core";
import { AssignmentQuestion } from "modules/questions/question.types";
import QuestionEditor from "./QuestionEditor";

import { useEffect, useRef } from "react";
import { TbPlus, TbRobot } from "react-icons/tb";
import { MdHistoryEdu } from "react-icons/md";
import AssignmentBuilderEmptyState from "~/components/AssignmentEditor/AssignmentBuilderEmptyState"; // Import the new component

interface QuestionsViewProps {
  questions: AssignmentQuestion[];
  assignmentId: string;
  canAddQuestions: boolean;
  onAIAssistantClick: () => void;
  onSearchContentClick: () => void;
  handleCreateEmptyQuestion: () => void; // Added prop
}

export default function QuestionsView({
  questions,
  assignmentId,
  canAddQuestions, // Keep destructuring
  onAIAssistantClick, // Use renamed prop
  onSearchContentClick,
  handleCreateEmptyQuestion, // Ensure prop is destructured
}: QuestionsViewProps) {
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = panelRef.current.scrollHeight;
    }
  }, [questions]);

  const showAddQuestionButton = Boolean(
    questions.length > 0 && canAddQuestions,
  );

  return (
    <div ref={panelRef}>
      {questions.map((item, index) => (
        <QuestionEditor key={item.question.id} item={item} index={index} />
      ))}
      <div className="flex justify-center flex-col items-center gap-5">
        {/* Render the new empty state component when there are no questions */}
        {questions.length === 0 && (
          <AssignmentBuilderEmptyState
            assignmentId={assignmentId}
            onAIAssistantClick={onAIAssistantClick}
            onSearchContentClick={onSearchContentClick}
            handleCreateEmptyQuestion={handleCreateEmptyQuestion} // Pass down prop
          />
        )}
        {/* The AddQuestionButton is removed as its functionality is now in AssignmentBuilderEmptyState */}
      </div>
      {showAddQuestionButton && (
        <div className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-6 my-4">
          <Button
            className="p-2 rounded-lg w-full md:w-auto"
            type="button"
            color="primary"
            leftSection={<TbRobot size={18} />}
            size="sm"
            onClick={onAIAssistantClick}
          >
            AI Create
          </Button>
          <Button
            className="p-2 rounded-lg w-full md:w-auto"
            type="button"
            color="primary"
            leftSection={<MdHistoryEdu size={18} />}
            size="sm"
            onClick={onSearchContentClick}
          >
            Search pre-made content
          </Button>
          <Button
            className="p-2 rounded-lg w-full md:w-auto"
            type="button"
            color="primary"
            leftSection={<TbPlus />}
            size="sm"
            onClick={handleCreateEmptyQuestion}
          >
            Manually add question
          </Button>
        </div>
      )}
    </div>
  );
}
